import { ModuleType, InsertType } from '../types';
import _op from 'pathfinder/rules-engine/targetingRules/_operators';
import { DESKTOP_RULE, LANGUAGE_EN_RULE, MEDIAWIKI_RULE, USER_LOGGED_IN_RULE, } from 'pathfinder/rules-engine/targetingRules/_templates';
export const IMPACT_NOTIFICATIONS_VARIANT_TO_COOKIE = {
    'impact-notifications-control': 'control',
    'impact-notifications-variant-A': 'A',
    'impact-notifications-variant-B': 'B',
};
const TARGETING_RULES = _op.all(USER_LOGGED_IN_RULE, MEDIAWIKI_RULE, DESKTOP_RULE, LANGUAGE_EN_RULE);
const disabled = false;
const impactNotificationsModules = [
    {
        disabled,
        name: 'impact-notifications-control',
        type: ModuleType.ImpactNotifications,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['0-31', '99'],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        disabled,
        name: 'impact-notifications-variant-A',
        type: ModuleType.ImpactNotifications,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['56-89'],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        disabled,
        name: 'impact-notifications-variant-B',
        type: ModuleType.ImpactNotifications,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['32-55', '90-98'],
        config: {},
        targeting: TARGETING_RULES,
    },
];
export default impactNotificationsModules;
