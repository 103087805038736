/** All the rules that Targeting supports */
export var TargetingType;
(function (TargetingType) {
    /** not for actual use */
    TargetingType["Example"] = "Example";
    /**
     * @description Always load the module; Use only in system modules.
     * @example
     * {
     *  type: TargetingType.AlwaysLoad
     * }
     * @targetingRule _alwaysLoad.ts
     */
    TargetingType["AlwaysLoad"] = "AlwaysLoad";
    /**
     * @description Only work for localhost/dev environments.
     * @example
     * {
     *  type: TargetingType.TestOnlyInDev
     * }
     * @targetingRule testOnlyInDev.ts
     */
    TargetingType["TestOnlyInDev"] = "TestOnlyInDev";
    /**
     * @description Only work for canary or a specific deployed branch environment (ss_branch_deploy).
     * @example
     * {
     *  type: TargetingType.TestOnlyOnBranch,
     *  branch: string
     * }
     * @targetingRule testOnlyOnBranch.ts
     */
    TargetingType["TestOnlyOnBranch"] = "TestOnlyOnBranch";
    /**
     * @description Validates specifics to the current page's context (pageId, product, language, productCategory, etc...).
     * @example
     * {
     *  type: TargetingType.CurrentContext,
     *  property: string,
     *  value?: string,
     *  adTags?: Partial<FlatAdTags>
     * }
     * @targetingRule currentContext.ts
     */
    TargetingType["CurrentContext"] = "CurrentContext";
    /**
     * @description Checks if the user is LoggedIn/Anon state.
     * @example
     * {
     *  type: TargetingType.UserLoggedIn,
     *  value: boolean
     * }
     * @targetingRule userLoggedIn.ts
     */
    TargetingType["UserLoggedIn"] = "UserLoggedIn";
    /**
     * @description Validates values within the user's recently visited pages.
     * @example
     * {
     *  type: TargetingType.VisitedPages,
     *  lastVisitedPages: number,
     *  property: string,
     *  value: string,
     *  adTags?: Partial<FlatAdTags>,
     *  atLeast: number
     * }
     * @targetingRule visitedPages.ts
     */
    TargetingType["VisitedPages"] = "VisitedPages";
    /**
     * @description Checks if the user's current device matches the rule.
     * @example
     * {
     *  type: TargetingType.UserDevice,
     *  deviceType?: UserDeviceType.Desktop | UserDeviceType.Mobile
     *  deviceOrientation?: UserDeviceOrientation.Portrait | UserDeviceOrientation.Landscape
     * }
     * @targetingRule userDevice.ts
     */
    TargetingType["UserDevice"] = "UserDevice";
    /**
     * @description Validates if a DOM node exists and optionally allows for the checking of a specific attribute, and its value.
     * @example
     * {
     *  type: TargetingType.DomNode,
     *  selector: string,
     *  attribute?: string // Checks if an attribute is present on a non-null Node selected from the 'selector' param.
     *  value?: string // regexp string - needs a valid attribute param passed in, otherwise the check won't occur.
     * }
     * @targetingRule domNode.ts
     */
    TargetingType["DomNode"] = "DomNode";
    /**
     * @description Allows a module to be available only between a date range.
     * @example
     * {
     *  type: TargetingType.DateRange,
     *  start?: number,
     *  end?: number
     * }
     * @targetingRule dateRange.ts
     */
    TargetingType["DateRange"] = "DateRange";
    /**
     * @description Allows modules to be available for a subset of Geos (US, EU, NZ, etc...).
     * @example
     * {
     *  type: TargetingType.UserGeo,
     *  country: Array<string>
     * }
     * @targetingRule userGeo.ts
     */
    TargetingType["UserGeo"] = "UserGeo";
    /**
     * @description Checks for the existence (or not) of a cookie.
     * @example
     * {
     *  type: TargetingType.Cookie,
     *  cookieName: string,
     *  value?: string
     * }
     * @targetingRule cookie.ts
     */
    TargetingType["Cookie"] = "UserCookie";
    /**
     * @description Search for the specified ActionType within SilverSurfer's saved actions.
     * @example
     * {
     *  type: TargetingType.SilverSurferAction,
     *  actionType: ActionType,
     *  // ActionType.IMPRESSION | SUBMIT | etc
     *  property?: string,
     *  value?: string
     * }
     * @targetingRule silverSurferAction.ts
     */
    TargetingType["SilverSurferAction"] = "SilverSurferAction";
    /**
     * @description Validates if the screen's width and/or height are within the defined treshhold.
     * @example
     * {
     *  type: TargetingType.ScreenSize,
     *  property: Screen.Width | Screen.Height,
     *  min?: number,
     *  max?: number
     * }
     * @targetingRule screenSize.ts
     */
    TargetingType["ScreenSize"] = "ScreenSize";
    /**
     * @description Validates whether the current url matches a value or any of the values.
     *
     * **Note**: This is a non-case-sensitive RegEx match.
     *
     * @example
     * {
     *  type: TargetingType.Url,
     *  href?: string | Array<string>;
     *  host?: string | Array<string>;
     *  pathname?: string | Array<string>;
     *  search?: string | Array<string>;
     * }
     * @targetingRule url.ts
     */
    TargetingType["Url"] = "Url";
    /**
     * @description Validates if the user came from a specific URL through navigation
     * @example
     * {
     *  type: TargetingType.Referrer,
     *  urlContains: string
     * }
     * @targetingRule referrer.ts
     */
    TargetingType["Referrer"] = "Referrer";
    /**
     * @description Checks if current page has a wiki category. Rule allows to check any from list.
     *
     * **Note**: This is a non-case-sensitive RegEx match.
     * **Note**: This is a DOM reading matcher that's fine for testing but not for anything else. It doesn't work for non-en wikis
     *
     * @example
     * {
     *  type: TargetingType.WikiCategory,
     *  any?: Array<string>
     * }
     * @targetingRule wikiCategory.ts
     */
    TargetingType["WikiCategory"] = "WikiCategory";
    /**
     * @description Validates values within `window.mw`'s configuration object.
     *
     * **Note**: Try to use `CurrentContext` rule first, only use this rule if the data is not supported by SS.
     *
     * @example
     * {
     * 	type: TargetingType.MwConfig,
     * 	property: string,
     * 	value:  boolean | string | number | object | Array<any>
     * }
     * @targetingRule mwConfig.ts
     */
    TargetingType["MwConfig"] = "mwConfig";
    /**
     * @description Checks if the wiki is a test wiki.
     * @example
     * {
     *  type: TargetingType.IsTestWiki,
     *  value: boolean
     * }
     * @targetingRule isTestWiki.ts
     */
    TargetingType["IsTestWiki"] = "IsTestWiki";
    /**
     * @description Checks if there are at least X DOM nodes matching given selector.
     * @example
     * {
     *  type: TargetingType.AtLeastDomNodes,
     *  selector: string
     *  min: number
     * }
     * @targetingRule atLeastDomNodes.ts
     */
    TargetingType["AtLeastDomNodes"] = "AtLeastDomNodes";
    /**
     * @description Checks for the user's current browser vendor.
     * @example
     * {
     *  type: TargetingType.Browser,
     *  value: Browser |  Array<Browser>
     * }
     *
     * @targetingRule browserRule.ts
     */
    TargetingType["Browser"] = "Browser";
    /**
     * @description Validates values within `window.fandomContext`'s object.
     *
     * @example
     * {
     * 	type: TargetingType.FandomContext,
     * 	context: 'site' | 'page',
     * 	property: string,
     * 	value:  boolean | string | number | object | Array<any>
     * }
     * @targetingRule fandomContext.ts
     */
    TargetingType["FandomContext"] = "FandomContext";
    /**
     * @description Validates values within `window.quizzes`'s object.
     *
     * @example
     * {
     * 	type: TargetingType.FandomQuizzes,
     * 	property: string,
     * 	value: boolean,
     * }
     * @targetingRule fandomQuizzes.ts
     */
    TargetingType["FandomQuizzes"] = "FandomQuizzes";
})(TargetingType || (TargetingType = {}));
/** All the possible supported modules */
export var ModuleType;
(function (ModuleType) {
    ModuleType["Example"] = "ExampleModule";
    ModuleType["InWikiEmail"] = "InWikiEmailModule";
    ModuleType["DebugMenu"] = "DebugMenuModule";
    ModuleType["HaloEffectVertical"] = "HaloEffectVerticalModule";
    ModuleType["WikiQuizConsumptionModule"] = "WikiQuizConsumptionModule";
    ModuleType["DiscussionQuizConsumptionModule"] = "DiscussionQuizConsumptionModule";
    ModuleType["TriviaQuizConsumptionModule"] = "TriviaQuizConsumptionModule";
    ModuleType["FanhubQuizConsumptionModule"] = "FanhubQuizConsumptionModule";
    ModuleType["QuizCreation"] = "QuizCreation";
    ModuleType["QuizMetaEditorModule"] = "QuizMetaEditorModule";
    ModuleType["NotificationCMS"] = "NotificationCMS";
    ModuleType["MarketingNotifications"] = "MarketingNotifications";
    ModuleType["SponsoredWidgetsCountdownClockConsumptionModule"] = "SponsoredWidgetsCountdownClockConsumptionModule";
    ModuleType["SponsoredWidgetsSponsoredLogoConsumptionModule"] = "SponsoredWidgetsSponsoredLogoConsumptionModule";
    ModuleType["WebPush"] = "WebPush";
    ModuleType["CollapseToC"] = "CollapseToC";
    ModuleType["MobileDrawer"] = "MobileDrawer";
    ModuleType["MobileDrawerValidation"] = "MobileDrawerValidation";
    ModuleType["ImpactNotifications"] = "ImpactNotifications";
})(ModuleType || (ModuleType = {}));
/** All types of possible insertion points */
export var InsertType;
(function (InsertType) {
    /** Add and show instantly, just for the test */
    InsertType["Instant"] = "Instant";
    /** Add to element `{selector: string}`. Optionally after XXXXX characters. */
    InsertType["Element"] = "Element";
    /** Add to mediawiki context after `{characters: number}` characters. */
    InsertType["UcpInContext"] = "UcpInContext";
    /** Custom insertion logic which will be implemented inside the component or an outside function and passed back alongside the component's render function */
    InsertType["Custom"] = "Custom";
})(InsertType || (InsertType = {}));
export var InsertElementMethod;
(function (InsertElementMethod) {
    InsertElementMethod["After"] = "After";
    InsertElementMethod["Append"] = "Append";
    InsertElementMethod["Before"] = "Before";
    InsertElementMethod["Prepend"] = "Prepend";
})(InsertElementMethod || (InsertElementMethod = {}));
/** All the operators that Targeting suports */
export var TargetingOperator;
(function (TargetingOperator) {
    TargetingOperator["And"] = "AND";
    TargetingOperator["Or"] = "OR";
    TargetingOperator["Not"] = "NOT";
})(TargetingOperator || (TargetingOperator = {}));
